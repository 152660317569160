@import 'styles/base/variables';
@import 'styles/base/mixins';

.insightsDetails {
  :global(.image--aspect-ratio) {
    line-height: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }

  :global(.hero-image-gdds .hero-image-themed) {
    [color] {
      // hides the content box
      display: none;
    }
  }
}


.socialButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.3125rem 0 2.1875rem 0;

  > div {
    flex-basis: 100%;
    margin: 0.3125rem 0;
  }

  > span {
    margin: 0.3125rem 0;
    margin-right: 1rem;
  }

  @include screen-md {
    margin: 2.3125rem 0;
    justify-content: right;

    > div {
      margin-right: 1rem;
      flex-basis: auto;
    }

    > span {
      margin-left: 1rem;
      margin-right: 0;
    }
  }
}

.topic {
  margin-bottom: 0.25rem;
  text-transform: uppercase;

  @include screen-md {
    margin-bottom: 0;
  }
}
