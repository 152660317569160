@import '../../styles/base/variables';
@import '../../styles/base/functions';
@import '../../styles/vendor/icons';
@import '../../styles/base/mixins';

$box-shadow: 0 0 10px #cbd2d2;

.container {
  margin-bottom: 5rem;

  @include below-screen-sm {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  @include below-screen-xs {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.inputCheckbox,
.inputRadio {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  & + span {
    margin: 0 1.875rem 1.25rem 0;
    display: block;
    cursor: pointer;
    padding-left: 1.875rem;
    position: relative;

    &::before {
      background: $gb_white;
      width: 1.25rem;
      height: 1.25rem;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 0.0625rem solid $gb_grey_200;
    }
  }
}

// Radiobutton styling
.inputRadio {
  & + span {
    &::before {
      border-radius: 50%;
    }

    &::after {
      background-color: $gb_grey_400;
      width: 0.625rem;
      height: 0.625rem;
      display: block;
      visibility: hidden;
      content: '';
      position: absolute;
      top: 0.3125rem;
      left: 0.3125rem;
      border-radius: 50%;
    }
  }

  &:hover {
    & + span {
      &::before {
        box-shadow: $box-shadow;
        border-color: $gb_grey_400;
      }

      &::after {
        visibility: visible;
      }
    }
  }

  &:checked + span {
    &::before {
      border-color: var(--gb-brand-primary-dark);
    }

    &::after {
      background-color: var(--gb-brand-primary-dark);
      visibility: visible;
    }
  }

  &:disabled {
    & + span {
      color: $gb_grey_400;
      cursor: default;

      &::before {
        border-color: $gb_grey_200;
      }

      &::after {
        background-color: $gb_grey_200;
      }
    }

    &:hover {
      & + span {
        &::before {
          box-shadow: none;
        }

        &::after {
          visibility: hidden;
        }
      }
    }
  }

  &:checked:disabled {
    &:hover {
      & + span::after {
        visibility: visible;
      }
    }
  }
}

// Checkbox styling - but not for gdds switch ;)
input[type='checkbox']:not([data-testid='switch-input']) {
  & + span::after {
    @include web20-icon('check');
    display: block;
    visibility: hidden;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    font-size: 0.75rem;
  }

  &:hover {
    & + span {
      &:before {
        box-shadow: $box-shadow;
      }

      &::after {
        visibility: visible;
      }
    }
  }

  &:checked + span {
    &::before {
      background-color: var(--gb-brand-primary-dark);
    }

    &::after {
      visibility: visible;
      color: $gb_white;
    }
  }

  &:disabled {
    & + span {
      color: $gb_grey_400;
      cursor: default;

      &::before {
        background-color: $gb_grey_060;
      }
    }

    &:hover {
      & + span {
        &::before {
          box-shadow: none;
        }

        &::after {
          visibility: hidden;
        }
      }
    }
  }

  &:checked:disabled {
    & + span::after,
    &:hover + span::after {
      color: $gb_grey_200;
      visibility: visible;
    }
  }
}
