@import '../../checkout.module.scss';

.cartFooter {
  background: $gb_grey_050;
  padding: $spacing-m;

  @include below-screen-sm {
    padding: 1rem;
  }

  &Text {
    margin-bottom: 1rem;
  }
}

.textAlignRight {
  text-align: right;
}

.voucherCartLine {
  color: $gb_gdds_cl18;
}

.bottomMargin {
  margin-bottom: $spacing-m !important;
}
