@import 'styles/base/variables';
@import 'styles/base/mixins';

div.wrapper {
  // weird spacings because the select field already has 8px padding
  margin: 1.25rem auto 2.5rem auto;

  @include screen-md {
    margin: 2rem auto 5rem auto;
  }

  > div {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }

  :global(.select) {
    margin: 0.5rem 0;
  }

  .resetButton {
    display: flex;
    align-items: flex-end;
    margin: 0.5rem 0;

    span {
      align-items: center;
    }
  }

  .chips {
    margin: 0.5rem 0;

    & > div > div {
      min-width: fit-content;
    }
  }
}
