@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.insightsTeaser {
  /* not the standard gdds spacing */
  margin: 2.5rem 0;

  @include screen-sm {
    margin: $spacing-gdds-section;
  }

  &.greyBackground {
    background-color: $gb_grey_050;
    margin: 0;
    padding: 2.5rem 0;

    @include screen-sm {
      padding: $spacing-gdds-section;
    }
  }

  &.whiteBackground {
    background-color: $gb_white;
  }
}

.animated {
  &.small {
    transition: opacity 1s, top ease-in-out 1s;
    opacity: 0;
    position: relative;
    top: 3rem;

    &.visible {
      opacity: 1;
      top: 0;
    }
  }

  &.medium {
    transition: opacity 0.5s, top ease-in-out 2s;
    opacity: 0;
    position: relative;
    top: 20rem;

    &.visible {
      opacity: 1;
      top: 0;
    }
  }

  &.teaserLarge {
    .imageWrapper {
      transition: opacity 0.5s, top ease-in-out 0.5s;
      opacity: 0;
      position: relative;
      top: 10rem;
    }

    .teaserContentWrapper {
      transition: opacity 1.5s, top ease-in-out 1.5s;
      opacity: 0;
      position: relative;
      top: 20rem;
    }

    &.visible {
      .imageWrapper {
        opacity: 1;
        top: 0;
      }

      .teaserContentWrapper {
        opacity: 1;
        top: 0;
      }
    }
  }

  /* for the zoom in effect we can't use scale because we need to zoom in in px */
  img {
    transition: transform ease 0.5s, box-shadow ease 0.5s;

    &:hover {
      box-shadow: 10px 10px 15px 5px rgba(0, 0, 0, 0.1);
    }
  }
}

.teaserWrapper {
  margin-top: 0.625rem;

  @include screen-sm {
    margin-top: 0.5rem;
  }

  :global(.teaserElement:first-of-type) {
    img {
      margin-top: 0;
    }
  }

  .imageContainer {
    margin-top: 1.25rem;

    @include screen-sm {
      margin-top: 0;
    }
  }

  /* workaround for unwanted white space after img-tag */
  :global(.image-content) {
    line-height: 0;
  }

  /* vertical alignment of carousel items should be top */
  li {
    align-items: flex-start;

    &:first-child > div {
      padding-left: 0;
    }
  }

  /* override global style; can be removed when global style is removed */
  p {
    font-size: 1rem;
  }
}

.white {
  background-color: $gb_white;
}

.grey {
  background-color: $gb_grey_050;
}

.linkWrapper {
  @include screen-sm {
    margin-top: -0.5rem;
    margin-bottom: 1.09375rem;
  }

  a {
    cursor: pointer;
  }
}

.teaserContentWrapper {
  font-size: 1rem;
  
  p {
    font-size: 1rem;
  }

  p,
  h4 {
    margin-top: 1.25rem;
    margin-bottom: 0;
    max-width: unset;

    @include screen-sm {
      margin-top: 1.5rem;
    }
  }

  a {
    margin-top: 1.25rem;
    cursor: pointer;

    @include screen-sm {
      margin-top: 1.09375rem;
    }
  }
}

.withoutPadding {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.listRow {
  margin: $spacing-gdds-section-mobile;

  @include screen-sm {
    margin: $spacing-gdds-section;
    display: flex;
  }

  > div {
    width: 100%;
  }

  :global(.image--aspect-ratio) {
    background-color: var(--gb-brand-primary);
  }

  img {
    width: 100%;
  }
}

.teaserLarge {
  margin: $spacing-gdds-section-mobile;

  @include screen-sm {
    margin: $spacing-gdds-section;
    display: flex;
  }

  &.right {
    flex-direction: row-reverse;
  }

  .imageWrapper {
    flex: 0 0 55.625%;
    line-height: 0;

    a {
      line-height: 0;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .teaserContentWrapper {
    padding: 1.25rem 1.5rem 0 1.5rem;

    @include screen-sm {
      padding-top: 1.5rem;
    }

    h4 {
      margin-top: 0;
    }

    .contentInner {
      max-width: 30.375rem;
    }
  }
}

// fallback when teaser has no image
.imageContainer {
  background: var(--gb-brand-primary);
  color: $gb_white;
  line-height: 0;

  a {
    width: 100%;
    line-height: 0;
  }
}

.innerRow {
  @include screen-sm {
    &:not(.firstItem) {
      margin-left: 0;
    }

    &:not(.lastItem) {
      margin-right: 0;
    }
  }
}
