@import 'styles/base/variables';
@import 'styles/base/mixins';

.heroimage {
  position: relative;
  width: 100%;

  &.isSection {
    margin-bottom: 3.75rem;
  }

  .styledContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .styledCol {
    position: relative;

    @include screen-sm {
      min-width: 50%;
      max-width: 75%;
    }

    @include screen-md {
      min-width: 33.33%;
      max-width: 50%;
    }
  }

  :global(.nord) &,
  :global(.kolo) &,
  :global(.twyford) & {
    margin-bottom: -0.375rem;

    &.homepage {
      margin-bottom: 4rem;
    }

    @include screen-sm {
      &.homepage {
        margin-bottom: 7.5rem;
      }
    }

    h1,
    h2 {
      margin-bottom: 0;

      span + span {
        font-family: $gb_nordicsFontQuickbrush;
        text-transform: inherit;
      }
    }

    h2 {
      &:global(.gdds-element) {
        font-size: 1.75rem;
        line-height: 2.25rem;

        @include screen-sm {
          font-size: 2.25rem;
          line-height: 2.75rem;
        }

        @include screen-md {
          font-size: 2.625rem;
          line-height: 3.25rem;
        }
        @include screen-lg {
          font-size: 2.875rem;
          line-height: 3.75rem;
        }
      }
    }
  }

  :global(.nord main.videocenter) &,
  :global(.kolo main.videocenter) &,
  :global(.twyford main.videocenter) {
    margin-bottom: 2.5rem;

    @include screen-sm {
      margin-bottom: 0;
    }
  }

  :global(.kolo) & {
    h1,
    h2 {
      span {
        font-weight: $font-weight-bold !important; // necessary
      }
      span + span {
        font-family: $gb_fontFamilyBase;
        text-transform: uppercase;
      }
    }
  }

  :global(.twyford) & {
    h1,
    h2 {
      span {
        text-transform: capitalize !important; // necessary
      }
      span + span {
        font-family: $gb_fontFamilyBase;
        font-weight: $font-weight-medium;
      }
    }
  }
}

.heroImageC {
  :global(.hero-image-c) {
    height: 100%;
  }

  :global(.hero-image-c__video-module) {
    margin: 0;
    padding: 0 0 56.25%;
    height: 0;
    position: relative;
    background: center no-repeat;
    background-size: cover;
  }

  img {
    width: 100%;
  }
}

.heroimageWrapper {
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.desktopCol {
  display: none;

  @include screen-sm {
    display: block;
  }
}

.heroimageCarousel {
  position: relative;
  [data-testid='carousel-button-right'],
  [data-testid='carousel-button-left'] {
    display: none;
  }

  [data-testid='carousel-indicator'] {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    z-index: 10;

    @include screen-sm {
      bottom: 1.5rem;
    }
  }
}

.buttonCarouselWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  display: none;

  .dark & {
    button {
      border: 1px solid $gb_black;

      svg {
        fill: $gb_black;
      }

      &:disabled {
        border: 1px solid transparent;

        svg {
          fill: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  button:disabled {
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, 0.08);
  }

  .light & {
    button {
      border: 1px solid $gb_white;

      svg {
        fill: $gb_white;
      }

      &:disabled {
        border: 1px solid transparent;

        svg {
          fill: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  @include screen-sm {
    display: block;
  }

  button {
    position: absolute;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    display: none;
    align-items: center;
    pointer-events: all;

    &:first-of-type {
      left: 1.5rem;
    }

    &:last-of-type {
      right: 1.5rem;
    }

    @include screen-sm {
      display: flex;
    }
  }
}

.styledRow,
.styledContainer {
  height: 100%;
  width: 100%;
}

.contentBoxWrapper {
  position: absolute;
  backdrop-filter: blur(0.25rem);
  top: 35%;
  min-width: min-content;
  max-width: 100%;
  transform: translateY(-35%);
  z-index: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  &.dark {
    background-color: $gb_black;
    opacity: 0.7;
    color: $gb_white;
  }

  &.light {
    background-color: $gb_white;
    opacity: 0.8;
    color: $gb_black;
  }

  h1 {
    margin-left: inherit;
  }

  button {
    align-self: flex-end;
  }

  @include screen-sm {
    padding: 2rem;
  }
}
