@import '../../checkout.module.scss';

.orderDetailsBoxWrapper {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    padding: 0;
    font-weight: $font-weight-regular;
  }
}

.bottomMargin {
  margin-bottom: $spacing-xs;
}
