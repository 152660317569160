@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.fullgridImage {
  margin-bottom: 1.25rem;

  @include screen-md {
    margin-bottom: 1.875rem;
  }

  &.greyBackground {
    background-color: $gb_grey_050;
  }

  &.whiteBackground {
    background-color: $gb_white;
  }

  &.isContainerChild {
    margin: $spacing-gdds-section-mobile;

    @include screen-md {
      margin: $spacing-gdds-section;
    }

    &.greyBackground {
      background-color: $gb_grey_050;
      margin: 0;
      padding: $spacing-gdds-section-mobile;

      @include screen-md {
        padding: $spacing-gdds-section;
      }
    }

    &.whiteBackground {
      background-color: $gb_white;
    }
  }

  .imageWrapper {
    line-height: 0;
  }

  img {
    width: 100%;
  }
}
