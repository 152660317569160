@import '../../checkout.module.scss';

.h1 {
  @include word-wrap();
  @include set-responsive-font-sizes(31, 35, 42);
  margin-bottom: $spacing-m;

  @include below-screen-sm {
    margin-top: $spacing-m;
    line-height: 2.5rem;
  }

  > span {
    display: block;
    font-size: inherit;
    font-weight: $font-weight-bold;
  }
}

.h3 {
  @include word-wrap();
  @include set-responsive-font-sizes(18, 20, 24);
  font-weight: $font-weight-bold;
}

.customerNumberText {
  display: block;
  font-size: inherit;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.bottomMargin {
  margin-bottom: $spacing-m;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.backButton {
  border: none !important;

  @include below-screen-sm {
    display: none;
  }
}

.editButton {
  margin-bottom: 2rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    color: #000;
  }
}

.cartOverviewDevider {
  color: black;
  width: 100%;
}

.loaderWrapper {
  display: block;
  width: 100%;
  margin: 30% 0;

  > div {
    margin: auto;
  }
}

.singlePaymentLabel {
  margin-left: $spacing-xs;
}

.termsAndConditions {
  @include below-screen-sm {
    padding: 1rem;
  }

  .radioInputWrapper {
    margin-right: $spacing-s;
  }

  .bottomMargin {
    margin-bottom: $spacing-xs;
  }

  .label {
    position: relative;
  }
}

