@import '../../styles/base/mixins';
@import '../../styles/base/variables';

.voucherLabel {
  font-size: 0.8rem;
  margin-bottom: 2px;
  color: $gb_grey_600;
}

.voucherForm {
  height: 2.5rem;
  display: flex;
  gap: 20px;
  @include below-screen-xs {
    flex-direction: column;
    gap: 10px;
  }
}

.voucherInput {
  width: 13rem;
  @include below-screen-xs {
    width: 100%;
  }
  @include screen-xs {
    flex-grow: 1;
  }
}

.voucherSubmitButton {
  padding: 4px;
  min-width: 5rem;
  flex-grow: 1;
  border: 1px solid #000;
  max-width: 3rem;
  font-weight: 700;

  @include screen-xs {
    max-width: 20rem;
  }

  &:disabled {
    pointer-events: none;
    border: 1px solid #ccc;
  }
}


