@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.anchorbarWrapper {
  margin: 0.75rem auto 4rem;

  div[data-testid='accordion-wrapper'] {
    min-height: auto;
    height: auto;
    padding: 0.75rem 0 0.75rem 0.5rem;
  }

  span[data-testid='accordion-title-elements'] {
    @include line-clamp(2);
    white-space: initial;
    padding: 0;

    @include screen-sm {
      display: inline-block;
      white-space: nowrap;
    }
  }

  .anchorbarLinkWrapper {
    margin: 0.313rem 0 0.75rem 0;

    a.anchorbarLink {
      height: auto;
      padding: 0.5rem 0;
      cursor: pointer;

      &:active {
        color: var(--gb-brand-primary-light);
        background-color: transparent;
        border-color: transparent;
        outline: none;

        span:last-of-type {
          border-bottom: 1px solid var(--gb-brand-primary);
        }

        svg {
          fill: var(--gb-brand-primary-light);
        }
      }
    }

    span {
      font-size: 1rem;
      font-weight: $font-weight-regular;
      margin: 0 0.438rem 0 0;
    }

    @include screen-sm {
      margin: 0.313rem 0 0.75rem 0;
    }
  }

  @include screen-sm {
    margin: auto;

    [class*='introTextWrapper']:has(+ &) {
      margin: 0.75rem auto 2.5rem;
    }
  }
}
