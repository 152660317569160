 @import '../../checkout.module.scss';

.h1 {
   @include word-wrap();
   @include set-responsive-font-sizes(31, 35, 42);
   margin-bottom: $spacing-m;

   @include below-screen-sm {
     margin-top: $spacing-m;
    line-height: 2.5rem;
   }

  > span {
    display: inline-block;
    font-size: inherit;
     font-weight: $font-weight-bold;
  }
}

.h3 {
   @include word-wrap();
   @include set-responsive-font-sizes(18, 20, 24);
   font-weight: $font-weight-bold;
}

.customerNumberText {
  display: block;
  font-size: inherit;
   font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.topMargin {
  margin-top: $spacing-m !important;
}

.bottomMargin {
   margin-bottom: $spacing-m !important;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.backButton {
  border: none !important;

   @include below-screen-sm {
    display: none;
   }
}

.cartFooter {
   background: $gb_grey_050;
   padding: $spacing-m;

   @include below-screen-sm {
    padding: 1rem;
   }

  &Text {
    margin-bottom: 1rem;
  }
}

.textAlignRight {
  text-align: right;
}

.cartOverviewDevider {
  color: black;
  width: 100%;
}

.loaderWrapper {
  display: block;
  width: 100%;
  margin: 30% 0;

  > div {
    margin: auto;
  }
}

.footerButtonRow {
  display: flex;
  justify-content: flex-end;

  > button {
    width: unset !important;
  }
}
